@import url('https://fonts.googleapis.com/css2?family=Finlandica:wght@400;500&display=swap');

body {
  background-color: #000;
  background: radial-gradient(ellipse at bottom, #111, #666, #000, #111, #333);
  background-size: 400% 400%;
  color: #000000;
  font-family: Finlandica, system-ui, -apple-system, BlinkMacSystemFont, sans-serif;
  font-size: 16pt;
  line-height: 1.5;
  margin: 0;
  padding: 0;
  animation: sunset 60s ease infinite;
}

@keyframes sunset {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 60%;
  }

  100% {
    background-position: 0% 40%;
  }
}

a {
  color: black;
  text-decoration: none;
}

a:hover {
  color: blue;
}

.hero {
  background-color: #fff;
  background: radial-gradient(ellipse at top, #eee, #ccc, #fff, #eee, #ddd);
  background-size: 400% 400%;
  min-height: 30em;
  margin: 2% 0;
  padding: 2%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 2em;
  row-gap: 1em;
  flex-wrap: wrap;
  opacity: 0.95;
  animation: sunset 50s ease infinite;
}

.hero-image {
  flex-grow: 0;
  text-align: center;
  flex-grow: 1;
  flex-shrink: 1;
}

.hero-image img {
  background-color: black;
  border: 0.5vh solid white;
  box-shadow: 0 0 5vh #ffffff;
  max-width: 100%;
  max-height: 50vh;
  min-width: 15em;
  padding: 2vh;
}

.hero-name {
  color: #333;
  flex-grow: 1.5;
  flex-shrink: 0.5;
  min-width: 20em;
}

.hero-skills {
  color: #999;
  column-gap: 1em;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  padding: 5vh 0;
}

.hero-links {
  flex-grow: 1;
  flex-shrink: 0.5;
}

.hero-links ul {
  display: flex;
  flex-direction: column;
  row-gap: 1em;
  flex-wrap: nowrap;
  align-content: space-between;
  justify-content: space-between;
  align-items: stretch;
  padding: 0;
  margin: 0;
  text-align: left;
}

.hero-links ul li {
  display: flex;
  flex-direction: row;
  font-weight: bold;
  font-size: 1.3vw;
  column-gap: 5pt;
  place-items: center;
  list-style: none;
  flex-grow: 1;
  width: 100%;
  text-align: left;
}

h1 {
  color: black;
  font-size: 3.2vw;
  line-height: 1.2;
  margin: 0;
  padding: 1% 0;
}

h2 {
  opacity: 0.7;
  font-size: 2vw;
  line-height: 1.2;
  margin: 0;
  padding: 0 0 3% 0;
}

h2 em {
  color: black;
  font-family: monospace;
  font-style: normal;
  font-weight: normal;
}

h3 {
  font-size: 1.5vw;
  line-height: 1.2;
  margin: 0;
  padding-bottom: 3vh;
  font-style: normal;
  font-weight: normal;
}

p {
  margin: 0;
  padding: 1em 0 1ex;
}
